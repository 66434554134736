<template>
    <section class="featured-products-wrap clearfix">
        <div class="container">
            <div class="tab-products-header clearfix" style="display: flex; align-items: center; justify-content: center; text-align: center; border-bottom: 0px;">
                <div class="featured-categories-text">
                    <h2 class="title">Our Products</h2>
                    <span class="excerpt">Choose from our variety of products!!!</span>
                </div>
            </div>

            <div class="tab-content">
                <div class="featured-products">
                    <div
                        v-for="(productChunks, index) in $chunk(products, 12)"
                        :key="1"
                        class="featured-products-slide d-flex"
                    >
                        <div
                            class="featured-products-item"
                            v-for="product in productChunks"
                            :key="product.id"
                        >
                            <ProductCard :product="product" />
                        </div>
                        <div class="featured-products-item" style="opacity: 0">

                        </div>
                    </div>
                </div>
            </div>

            <dynamic-tab
                v-for="(tabLabel, index) in data"
                :key="index"
                :label="tabLabel"
                :url="
                    route('storefront.product_grid.index', {
                        tabNumber: 2,
                    })
                "
            >
            </dynamic-tab>
        </div>
    </section>
</template>

<script>
import {slickPrevArrow, slickNextArrow} from "../../functions";
import ProductCard from "../ProductCard.vue";
import DynamicTabsMixin from "../../mixins/DynamicTabsMixin";

export default {
    components: {ProductCard},

    mixins: [DynamicTabsMixin],

    props: ["data"],

    methods: {
        selector() {
            return $(".featured-products");
        },

        slickOptions() {
            return {
                rows: 4,
                dots: false,
                arrows: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                variableWidth: true,
                rtl: window.FleetCart.rtl,
                prevArrow: slickPrevArrow(),
                nextArrow: slickNextArrow(),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: true,
                            arrows: false,
                        },
                    },
                ],
            };
        },
    },
};
</script>
